<template>
	<v-app full-height class="overflow-y-hidden">
		<NavBar :routes="routes" :current="currentRoute" />
		<NavDrawer :routes="routes" />
		<v-main>
			<RouterView v-slot="{ Component }">
				<Section v-if="Component" :component="Component" />
			</RouterView>
		</v-main>
	</v-app>
</template>

<script setup>
	import NavBar from "@/components/NavBar";
	import NavDrawer from "@/components/NavDrawer";
	import Section from "@/components/Section";

	import { computed, ref } from "vue";
	import { useRoute } from "vue-router";

	const route = useRoute();
	const routes = ref(["Creative", "Resume"]);

	const currentRoute = computed(() => route.name);
</script>

<style>
	@import "@/assets/css/main.css";
</style>
