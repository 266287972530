<template>
	<div>
		<v-app-bar v-if="smAndUp" class="px-12 bg-background" elevation="0">
			<v-row justify="space-between text-butter nav">
				<div class="d-flex align-center">
					<v-app-bar-title>
						<router-link to="/" class="text-decoration-none">
							Torrence Brown
						</router-link>
					</v-app-bar-title>
				</div>

				<div class="route-container">
					<router-link
						v-for="route in routes"
						:key="route"
						class="text-capitalize route-link"
						:class="{ 'font-weight-black scaled': route === current }"
						:to="{ name: route }"
					>
						{{ route }}
					</router-link>
				</div>
			</v-row>
		</v-app-bar>

		<v-app-bar v-else class="bg-background">
			<template #append>
				<v-app-bar-nav-icon
					@click="store.toggleNavDrawer()"
					color="butter"
				></v-app-bar-nav-icon>
			</template>

			<template #prepend>
				<v-app-bar-title>
					<v-avatar color="butter" size="large">
						<router-link to="/" class="text-decoration-none">
							<span class="text-black">TB</span>
						</router-link>
					</v-avatar>
				</v-app-bar-title>
			</template>
		</v-app-bar>
	</div>
</template>
<script setup>
	import { defineProps } from "vue";
	import { useDisplay } from "vuetify";
	import { useAppStore } from "@/stores/app.js";

	const store = useAppStore();
	const { smAndUp } = useDisplay();

	const { routes, current } = defineProps({
		routes: {
			type: Array,
			default: () => [],
		},
		current: {
			type: String,
			default: "",
		},
	});
</script>
<style scoped>
	.route-container {
		display: flex;
		gap: 3.5rem;
	}

	.scaled {
		transform: scale(1.15);
		transition: all 250ms ease;
	}

	.route-link {
		text-decoration: none;
		color: inherit;
	}
</style>
